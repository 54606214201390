<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            v-if="skin !== 'dark'"
            width="230px"
            src="@/assets/images/logo-text-black.png"
          />
          <b-img
            v-else
            width="230px"
            src="@/assets/images/logo-text-white.png"
          />
        </b-link>

        <b-card-title class="mb-1">
          Esqueceu sua senha? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Digite seu email para a gente te passar instruções de como recuperar
          sua senha
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              :disabled="loading"
              variant="primary"
              block
              type="submit"
            >
              <span v-if="!loading">Enviar link de recuperação de senha</span>
              <span v-else>
                <b-spinner
                  v-if="loading"
                  small
                />
              </span></b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'auth-login-v1' }">
            <feather-icon icon="ChevronLeftIcon" /> Voltar ao Login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BImg, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BSpinner
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BImg,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BForm,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      userEmail: '',
      // validation
      required,
      email,
      loading: false
    }
  },
  computed: {
    skin () {
      return store.state.appConfig.layout.skin
    }
  },
  methods: {
    validationForm () {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          this.$http.post('/auth/reset_password', {
            email: this.userEmail
          })
            .then(res => {
              if (res.data.message === 'User not found') this.userNotFound()
              if (res.data.message === 'Password reset link sent') this.success()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    userNotFound () {
      this.$swal({
        title: 'Nenhum usuário com este endereço de email encontrado.',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    success () {
      this.$swal({
        title: 'Um email com as instruções de recuperação de senha foi enviado para você.',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      }).then(() => {
        this.$router.push({ name: 'auth-login' })
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/pages/page-auth.scss";
</style>
